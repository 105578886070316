/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'align-top': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<rect pid="0" width="4" height="12" rx="1" transform="matrix(1 0 0 -1 6 15)"/><path pid="1" d="M1.5 2a.5.5 0 010-1zm13-1a.5.5 0 010 1zm-13 0h13v1h-13z"/>',
    },
});
